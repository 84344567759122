









































import { Property } from "@/graphql/client";
import { Component, Emit, Vue } from "vue-property-decorator";
import propertyService from "@/service/propertyService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class PropertyEditDialog extends Vue {
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: Property = propertyService.defaultProperty;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  /**
   * ダイアログを表示します.
   */
  public open(item: Property): void {
    this.item = item;

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  deleteItem(): void {
    this.isProgressing = true;
    //delete
    propertyService.deleteProperty(this.item).then((result) => {
      this.isProgressing = false;
      this.notifySuccess(result as Property);
    });
  }

  /**
   * 処理成功
   */
  @Emit("onSuccess")
  public notifySuccess(deleted: Property): Property {
    return deleted;
  }
}
