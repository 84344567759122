










import SettingSubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import PropertyList from "@/components/organisms/property/PropertyList.vue";

export default {
  components: {
    SettingSubPageTemplate,
    PropertyList,
  },
};
