






















































import { Component, Ref, Mixins } from "vue-property-decorator";
import { Property } from "@/graphql/client";
import propertyService from "@/service/propertyService";
import { NotificationState } from "@/store/modules/notification";
import PropertyDeleteDialog from "@/components/organisms/property/PropertyDeleteDialog.vue";
import PropertyEditDialog from "@/components/organisms/property/PropertyEditDialog.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";

@Component({
  components: {
    PropertyDeleteDialog,
    PropertyEditDialog,
  },
})
export default class PropertyList extends Mixins(MixinFormatter) {
  @Ref() readonly editDialog!: PropertyEditDialog;
  @Ref() readonly deleteDialog!: PropertyDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: Property[] = [];
  readonly headers = [
    {
      text: "スラッグ名称",
      value: "slugName",
      width: "20%",
    },
    {
      text: "名称",
      value: "name",
    },
    {
      text: "タイプ",
      value: "propertyType",
      width: "20%",
      formatter: this.getPropertyTypeLabel,
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];

  searchKeyword = "";
  loadingDataGrid = false;
  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------

  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    propertyService.allProperties().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }

  /**
   * プロパティ編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(propertyService.defaultProperty);
  }

  /**
   * プロパティ編集ダイアログを表示します.
   */
  public openEditDialog(item: Property): void {
    this.editDialog.open(item);
  }

  /**
   * プロパティ編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: Property): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("プロパティを更新しました！");
  }

  /**
   * プロパティ編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: Property): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("プロパティを登録しました！");
  }

  /**
   * プロパティ削除ダイアログを表示します.
   */
  public openDeleteDialog(item: Property): void {
    this.deleteDialog.open(item);
  }

  /**
   * プロパティ削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: Property): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("プロパティを削除しました！");
  }
}
